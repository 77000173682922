import styles from './successSignUp.module.scss'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../../routes/index'
import { Button } from '@mui/material'
import mailsend from '../../../assets/images/images/Authentication/mailsend.svg'

function SuccessSignup() {
  const navigate = useNavigate()

  return (
    <div className={styles.Container}>
      <div className={styles.InviteCard}>
        <img className={styles.InviteSvg} src={mailsend} alt="" />
        <div className={styles.DescContainer}>
          <span className={styles.Text}>Hello!</span>
          <br />
          <span className={styles.BoldText}>
            An activation link was sent to your email, please click it to verify
            your account
          </span>
        </div>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={styles.button}
          onClick={() => {
            navigate(ROUTES.SIGN_IN)
          }}
        >
          Sign In
        </Button>
      </div>
    </div>
  )
}

export default SuccessSignup
