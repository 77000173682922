const ROUTES = {
  HOME_PAGE: '/',
  SIGN_IN: '/auth/signin',
  SIGN_UP: '/auth/signup',
  RESET_PASSWORD: '/auth/resetPassword',
  EMAIL_VERIFICATION: '/auth/emailVerification',
  SUCCESS_SIGNUP: '/auth/successSignup',
  INVITE_PROJECT: '/invite/:inviteToken',
  SETTINGS: '/settings',
  PROJECTS: '/projects',
  PROJECT_PAGE: '/projects/:projectName/tracking',
  PROJECT_DATA_MANAGEMENTS: '/projects/:projectName/dataManagements',
  PROJECT_DATA_MANAGEMENTS_EXPORT:
    '/projects/:projectName/dataManagements/export',
  PROJECT_DATA_MANAGEMENTS_IMPORT:
    '/projects/:projectName/dataManagements/import',
  PROJECT_GUIDELINES: '/projects/:projectName/guidelines',
  PROJECT_ANALYSIS: '/projects/:projectName/analysis',
  PROJECT_REVIEW: '/projects/:projectName/review',
  PROJECT_TASK: '/projects/:projectName/task',
  BILLING: '/billing',
  EMAIL_HANDLER: '/emailHandler',
  PROJECT_CREATE_STEP_0: '/projects/create/step-0',
  PROJECT_CREATE_STEP_1: '/projects/create/step-1',
  PROJECT_CREATE_STEP_2: '/projects/create/:projectName/step-2',
  PROJECT_CREATE_STEP_3: '/projects/create/:projectName/step-3',
  PROJECT_CREATE_STEP_4: '/projects/create/:projectName/step-4',
  PROJECT_CREATE_STEP_5: '/projects/create/:projectName/step-5',
  DOCS: '/docs',
}

export default ROUTES
